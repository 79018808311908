<script lang="ts">
	import TreeView from "./TreeView.svelte";

	export let dataStore;

	const rTreeTasks = dataStore.treeTasks;

</script>

<TreeView on:action data={$rTreeTasks} {dataStore} />
