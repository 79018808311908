<script lang="ts">
	import Icon from "./Icon.svelte";
	import Avatar from "./Avatar.svelte";

	export let id = null;
	export let label = null;
	export let hotkey = null;
	export let icon = null;
	export let avatar = null;

	export let clickable = false;
	export let custom = false;
	export let disabled = false;
	export let checked = false;
	export let hover = true;
	export let minWidth = "200px";

</script>

<div
	class="wx-todo_item"
	class:wx-todo_item--clickable={clickable}
	class:wx-todo_item--disabled={disabled}
	class:wx-todo_item--checked={checked}
	class:wx-todo_item--hover={hover}
	class:wx-todo_item__avatar={avatar?.color || avatar?.avatar}
	data-item-id={disabled ? null : id}
	style="min-width: {minWidth};">
	{#if custom}
		<slot />
	{:else}
		{#if icon}
			<Icon name={icon} {clickable} {disabled} />
		{/if}
		{#if avatar?.avatar}
			<div style="margin-left: {icon ? 8 : 0}px">
				<Avatar avatar={avatar?.avatar} color={avatar.color} />
			</div>
		{/if}
		{#if label}
			<div class="wx-todo_item__label">{label}</div>
		{/if}
		{#if hotkey}
			<div class="wx-todo_item__hotkey">{hotkey}</div>
		{/if}
	{/if}
</div>

<style>
	.wx-todo_item {
		cursor: default;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		padding: 8px;

		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		line-height: var(--wx-line-height);

		color: var(--wx-color-font);
	}
	.wx-todo_item--clickable {
		cursor: pointer;
	}
	.wx-todo_item--disabled {
		cursor: default;
		color: var(--wx-color-font-disabled);
	}
	.wx-todo_item--hover:hover,
	.wx-todo_item--checked {
		background: var(--wx-background-selected);
	}
	.wx-todo_item__label {
		padding: 0 8px;
	}
	.wx-todo_item__hotkey {
		color: var(--wx-color-secondary-light);
	}
	.wx-todo_item__avatar {
		padding: 4px 8px;
	}

</style>
