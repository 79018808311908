<script lang="ts">
	export let name;
	export let size = 20;
	export let clickable = false;
	export let disabled = false;
	export let css = "";

</script>

<i
	on:click
	class="wx-todo_icon wxi wxi-{name} {css ? css : 'wx-todo_icon--default'}"
	class:wx-todo_icon--clickable={clickable}
	class:wx-todo_icon--disabled={disabled}
	style="font-size:{size}px; height:{size}px; width:{size}px" />

<style>
	.wx-todo_icon {
		display: flex;
		align-items: center;
	}
	.wx-todo_icon--default {
		color: var(--wx-icon-color);
	}
	.wx-todo_icon--clickable {
		cursor: pointer;
	}
	.wx-todo_icon--disabled {
		cursor: default;
		color: var(--wx-color-font-disabled);
	}

</style>
