<script lang="ts">
	import Item from "../../../library/Item.svelte";
	import Menu from "../../../library/Menu.svelte";

	import type { ICoords, IUserItem } from "@xbs/lib-todo/dist/types";

	export let coords: ICoords;
	export let data: IUserItem[];

</script>

<Menu {coords} {data} clickable={false} let:item on:cancel>
	<Item
		label={item.label}
		avatar={{ avatar: item.avatar, color: item.color }}
		hover={false} />
</Menu>
