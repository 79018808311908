<script lang="ts">
	import { getCountLabel, IApi, isDefined, isSameId } from "@xbs/lib-todo";
	import { locateID } from "@xbs/lib-dom";
	import { getContext } from "svelte";

	import Separator from "../../library/Separator.svelte";
	import Icon from "../../library/Icon.svelte";
	import Input from "../../library/Input.svelte";
	import Text from "../../library/Text.svelte";
	import Popup from "../../library/Popup.svelte";

	import type { IProjectItem } from "@xbs/lib-todo/dist/types";

	export let api: IApi;

	const _ = getContext("wx-i18n").getGroup("todo");

	let node: HTMLElement = null;
	let dropdown = {
		open: false,
		coords: { x: 0, y: 0 },
	};

	$: state = api.getReactiveState();

	$: editor = state.editableItem;
	$: activeProject = state.activeProject;
	$: projects = state.projects;
	$: tasks = state.tasks;
	$: componentId = state.id;
	$: readonly = state.readonly;

	$: editing =
		isSameId($editor?.id, $activeProject) && $editor?.type === "project";

	$: visibleProjects =
		$projects.every(p => isDefined(p.id)) &&
		$tasks.some(t => !isDefined(t.project) && !isDefined(t.parent))
			? [{ id: null, label: _("No project") }, ...$projects]
			: $projects;
	$: projectLabel = (
		visibleProjects.find(p => isSameId($activeProject, p?.id))?.label ||
		_("No project")
	).trim();

	let search: string = null;
	let searchResult: IProjectItem[] = [];
	$: if (isDefined(search)) {
		searchResult = visibleProjects.filter(
			({ label }): IProjectItem =>
				label.toLowerCase().includes(search.toLowerCase())
		);
	} else {
		searchResult = visibleProjects;
	}

	function dropdownToggle() {
		dropdown.open = !dropdown.open;

		if (node && dropdown.open) {
			const rect = node.getBoundingClientRect();
			dropdown.coords = { x: rect.left, y: rect.bottom };
		}
	}

	function handleDown(event) {
		event = event.detail.event;
		if (dropdown.open && !node.contains(event.target)) {
			dropdown.open = false;
		}
	}

	function handleItemClick(event) {
		api.exec("set-project", { id: locateID(event, "data-item-id") });
		dropdownToggle();
	}

	function handleOpenEditor() {
		api.exec("open-inline-editor", {
			id: $activeProject,
			type: "project",
		});
	}

	function handleCloseEditor() {
		isDefined($editor?.id) &&
			api.exec("close-inline-editor", {
				id: $editor.id,
			});
	}

	function editInlineEditor(event) {
		const value = event.detail.value.trim();
		const otherProjects = $projects.filter(
			({ label }) => label !== projectLabel
		);

		api.exec("edit-item", {
			id: $editor.id,
			currentValue: value.length
				? value
				: getCountLabel("Untilted", otherProjects, _),
		});
	}

</script>

<div bind:this={node} data-todo-id={$componentId} class="wx-todo_combo">
	<div
		class="wx-todo_combo__wrapper"
		on:click|stopPropagation={dropdownToggle}>
		<Text
			{editing}
			value={projectLabel}
			enableRange={true}
			placeholder={_('Type what you want')}
			editable={isDefined($activeProject) && !$readonly}
			on:close={handleCloseEditor}
			on:open={handleOpenEditor}
			on:editing={editInlineEditor} />
		<div class="wx-todo_combo__icon">
			<Icon name={'menu-down'} clickable />
		</div>
	</div>
</div>

{#if dropdown.open && !editing}
	<Popup coords={dropdown.coords} on:cancel={handleDown}>
		<div class="wx-todo_combo-dropdown">
			<div class="wx-todo_combo-search">
				<div class="wx-todo_combo-search__icon">
					<Icon name="search" />
				</div>
				<Input placeholder={_('Search project')} bind:value={search} />
			</div>
			<Separator padding={'0'} />
			<div
				class="wx-todo_combo-dropdown__content"
				on:click={handleItemClick}>
				{#each searchResult as project (project.id)}
					<div class="wx-todo_combo-item" data-item-id={project.id}>
						<div class="wx-todo_combo-item__label">
							{project.label}
						</div>
						{#if isSameId($activeProject, project.id)}
							<Icon name={'check'} />
						{/if}
					</div>
				{/each}
			</div>
		</div>
	</Popup>
{/if}

<style>
	.wx-todo_combo {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		height: 100%;
		width: 100%;
		margin-right: 12px;
		margin-left: 22px;
	}
	.wx-todo_combo__wrapper {
		display: flex;
	}
	.wx-todo_combo-search {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		padding: 8px;
	}
	.wx-todo_combo-search .wx-todo_combo-search__icon {
		margin-right: 8px;
	}
	.wx-todo_combo-dropdown__content {
		max-height: 360px;
		min-width: calc(var(--wx-base-size) * 10);
		overflow-y: auto;
	}
	.wx-todo_combo-item {
		cursor: pointer;

		display: flex;
		align-items: center;
		justify-content: flex-start;

		padding: 8px;

		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		line-height: var(--wx-line-height);

		color: var(--wx-color-font);
	}
	.wx-todo_combo-item:hover {
		background: var(--wx-background-selected);
	}
	.wx-todo_combo-item__label {
		width: 100%;
		padding: 0 8px;
	}

</style>
