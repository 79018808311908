<script lang="ts">
	import { locateID, locate } from "@xbs/lib-dom";
	import { changeLabelLocale, isDefined, isSameId, uid } from "@xbs/lib-todo";
	import { createEventDispatcher, getContext } from "svelte";

	import Icon from "../../../library/Icon.svelte";
	import Separator from "../../../library/Separator.svelte";
	import Popup from "../../../library/Popup.svelte";
	import Item from "../../../library/Item.svelte";

	export let data = [];
	export let coords = null;
	export let id = uid();

	const _ = getContext("wx-i18n").getGroup("todo");
	const menuData = changeLabelLocale(data, _);

	let hoverItem = null;
	$: subItem = data.find(i => isSameId(i.id, hoverItem));

	$: parentNode = null;
	$: subMenu = {
		id: null,
		coords: { x: null, y: null },
		node: null,
	};

	$: if (isDefined(subMenu?.id) && isDefined(subMenu?.node)) {
		const parentRect = parentNode.getBoundingClientRect();
		const subMenuRect = subMenu.node.getBoundingClientRect();
		const bodyRect = document.body.getBoundingClientRect();
		const { offsetWidth, offsetHeight } = parentNode;

		let y = parentRect.y;
		let x = parentRect.x + parentRect.width;

		if (y + offsetHeight > bodyRect.bottom) {
			y = y - offsetHeight;
		}
		if (x + offsetWidth > bodyRect.right) {
			x = x - offsetWidth - subMenuRect.width;
		}

		subMenu.coords.x = x;
		subMenu.coords.y = y;

		if (y + subMenuRect.height > bodyRect.bottom) {
			subMenu.node.style.height = `${bodyRect.bottom - y}px`;
		}
	}

	function handleItemOver(event) {
		const itemId = locateID(event, "data-menu-item-id");
		const parentId = locateID(event, "data-menu-parent-id");

		hoverItem = isDefined(parentId) ? parentId : itemId;

		if (
			isDefined(itemId) &&
			!isDefined(parentId) &&
			subItem?.items?.length
		) {
			parentNode = locate(event, "data-menu-item-id");
			subMenu.id = itemId;
		}
	}

	const dispatch = createEventDispatcher();

	function handleMenuClick(event) {
		const itemId = locateID(event, "data-menu-item-id");
		const parentId = locateID(event, "data-menu-parent-id");

		if (isDefined(parentId)) {
			dispatch("click", {
				id: parentId,
				extra: itemId,
			});
		} else if (isDefined(itemId)) {
			dispatch("click", {
				id: itemId,
			});
		}
		parentId === "moveProject" && closeSubMenu(); // Dirty
	}

	function closeSubMenu() {
		subMenu = {
			id: null,
			coords: { x: null, y: null },
			node: null,
		};
	}

</script>

<svelte:window on:pointerover|stopPropagation={handleItemOver} />

<Popup {coords} offset={10}>
	<ul
		on:click|stopPropagation={handleMenuClick}
		data-menu-id={id}
		class="wx-todo_menu">
		{#each menuData as item}
			{#if item.type === 'separator'}
				<Separator padding="0" />
			{:else}
				<li class="wx-todo_menu-item" data-menu-item-id={item.id}>
					<div class="wx-todo_menu-item__wrapper">
						<Icon name={item.icon} />
						<span
							class="wx-todo_menu-item__description">{item.label}</span>
					</div>
					{#if item?.items?.length}
						<Icon name={'menu-right'} />
					{:else if item.hotkey}
						<span class="wx-todo_menu-item__hotkey">{item.hotkey}
						</span>
					{/if}
				</li>
			{/if}
		{/each}
	</ul>
</Popup>

{#if isDefined(subItem?.id) && isSameId(subMenu?.id, subItem?.id)}
	<Popup coords={subMenu.coords}>
		<ul
			bind:this={subMenu.node}
			on:click|stopPropagation={handleMenuClick}
			data-menu-id={id}
			class="wx-todo_menu">
			{#each subItem?.items || [] as el}
				<li data-menu-parent-id={subItem.id} data-menu-item-id={el.id}>
					<Item
						label={el.label}
						avatar={{ avatar: el.avatar, color: el.color }}
						icon={el.checked ? 'check' : ' '}
						checked={el.checked}
						clickable />
				</li>
			{/each}
		</ul>
	</Popup>
{/if}

<style>
	.wx-todo_menu {
		padding: 0;
		margin: 0;
		list-style: none;
		overflow-y: auto;

		background: var(--wx-background);
		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		line-height: var(--wx-line-height);
	}
	.wx-todo_menu-item {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;

		padding: 8px;
		min-width: calc(var(--wx-base-size) * 10);
	}
	.wx-todo_menu-item:hover {
		background: var(--wx-background-selected);
	}
	.wx-todo_menu-item__wrapper {
		display: flex;
	}
	.wx-todo_menu-item__description {
		color: var(--wx-color-font);
		padding: 0 8px;
	}
	.wx-todo_menu-item__hotkey {
		color: var(--wx-color-secondary-light);
	}

</style>
