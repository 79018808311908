<script lang="ts">
	import List from "../List/List.svelte";
	import Separator from "../../library/Separator.svelte";

	import type { ITaskTreeItem } from "@xbs/lib-todo/dist/types";

	export let dataStore;
	export let data: ITaskTreeItem[];
	export let level = 0;

	const rSelected = dataStore.selected;
	const rEditor = dataStore.editableItem;
	const rMenu = dataStore.menu;
	const rFilter = dataStore.filter;
	const rTags = dataStore.tags;
	const rShape = dataStore.taskShape;
	const rReadonly = dataStore.readonly;
	const rStateDueDate = dataStore.stateDueDate;

</script>

<ul class="wx-todo_tree">
	{#each data as task (task.id)}
		<li class="wx-todo_tree__row">
			<List
				on:action
				{task}
				level={level + 1}
				readonly={$rReadonly}
				editor={$rEditor}
				selected={$rSelected}
				menu={$rMenu}
				filter={$rFilter}
				shape={$rShape}
				tags={$rTags}
				dueDate={$rStateDueDate} />
			<slot />
			{#if task.children.length && !task.collapsed}
				<svelte:self
					on:action
					{dataStore}
					level={level + 1}
					data={task.children} />
			{/if}
			{#if level === 0}
				<Separator />
			{/if}
		</li>
	{/each}
</ul>

<style>
	.wx-todo_tree {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		height: 100%;
		width: 100%;
		margin: 0;
		padding: 0;
	}
	.wx-todo_tree__row {
		display: flex;
		flex-direction: column;
	}

</style>
