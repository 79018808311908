<script lang="ts">
	import {
		setCaretPosition,
		getCaretPosition,
		getCaretCoords,
	} from "@xbs/lib-dom";
	import {
		getMatchChar,
		getTagsData,
		hasWhiteSpace,
		htmlEscapes,
		isDefined,
	} from "@xbs/lib-todo";
	import DatePicker from "../../../library/DatePicker.svelte";
	import Menu from "../../../library/Menu.svelte";
	import Item from "../../../library/Item.svelte";
	import { createEventDispatcher, onMount } from "svelte";

	import type { IEditableItem, ITaskShape } from "@xbs/lib-todo/dist/types";

	export let value = "";
	export let placeholder = "";
	export let tags: string[] = [];
	export let shape: ITaskShape;
	export let editor: IEditableItem;

	let node: HTMLElement = null;
	let editableValue: string = null;
	let openCarretPosition: number = null;

	const dispatch = createEventDispatcher();

	onMount(openEditor);

	function openEditor(): void {
		editableValue = value;

		if (editor.targetDate) {
			openCarretPosition = editableValue.indexOf(editor.targetDate) - 2;
			setCaretPosition(node, openCarretPosition);
			openDropdown("datepicker");
		} else {
			setCaretPosition(node);
		}
	}

	function openDropdown(type: "menu" | "datepicker"): void {
		const LINE_HEIGHT = 20;
		const { x, y } = getCaretCoords();

		dispatch("editing", {
			value: editableValue,
			dropdown: {
				type,
				coords: { x, y: y + LINE_HEIGHT },
				data: type === "menu" ? getTagsData(tags, "") : [],
			},
			targetDate: editor.targetDate,
		});
	}

	function closeDropdown(): void {
		dispatch("editing", {
			dropdown: null,
			value: editableValue,
		});
	}

	function closeDatePicker(): void {
		innerValue(editableValue, "", "!");
		closeDropdown();
	}

	function handleEditorEdit(): void {
		editableValue = node?.textContent || "";
		dispatch("editing", {
			value: editableValue,
			dropdown: editor.dropdown,
		});
	}

	function handleKeyUp(event: KeyboardEvent): void {
		if (event.code === "Enter") return;

		const code = { sharp: "#", note: "!" };
		const caretPosition = getCaretPosition(node) - 1;
		const char = editableValue[caretPosition];
		const prevChar = editableValue[caretPosition - 1];
		const space = isDefined(prevChar) ? hasWhiteSpace(prevChar) : true;

		const isTag =
			(char === code["sharp"] || event.key === code["sharp"]) && space;
		const isDatePicker =
			(char === code["note"] || event.key === code["note"]) && space;

		if (isTag) {
			openDropdown("menu");
			openCarretPosition = caretPosition;
		} else if (isDatePicker) {
			openDropdown("datepicker");
			openCarretPosition = caretPosition;
		} else if (editor.dropdown) {
			const match = [];
			const space = new RegExp(/^\s*$/gm);

			for (let index = caretPosition; index > 0; index--) {
				const char = editableValue[index];

				if (char === code["sharp"]) {
					const string = match.reverse().join("");
					const data = getTagsData(tags, string);

					if (!data.length) break;

					dispatch("editing", {
						dropdown: { ...editor.dropdown, data },
						value: editableValue,
					});
					return;
				} else {
					match.push(char);
				}
				if (space.test(char)) break;
			}
			closeDropdown();
		}
	}

	function innerValue(string: string, substring: string, char: string): void {
		const nextChar = string[openCarretPosition + 1];
		const compareString = string;

		substring = char + substring;

		if (!nextChar) {
			string += substring.substring(1);
		} else if (hasWhiteSpace(nextChar)) {
			const matchChar = new RegExp(String.raw`${char}\s`, "gm");
			string = string.replace(matchChar, (match, offset): string => {
				if (offset === openCarretPosition) {
					return `${substring} `;
				}
				return match;
			});
		} else {
			if (char === "!") {
				const matchChar = new RegExp(/!\((.+?)\)/gm);
				string = string.replace(
					matchChar,
					(match, _, offset): string => {
						if (offset === openCarretPosition) {
							return substring;
						}
						return match;
					}
				);
			} else {
				string = string.replace(
					getMatchChar(char),
					(match, offset): string => {
						if (offset === openCarretPosition) {
							return substring;
						}
						return match;
					}
				);
			}
		}

		editableValue = string;
		node.innerHTML = htmlEscapes(string);

		setCaretPosition(
			node,
			openCarretPosition +
				(compareString !== editableValue ? substring.length : 1)
		);
		closeDropdown();
	}

	function handleMenuItemClick(event: CustomEvent): void {
		const tag = event.detail.id;
		if (!isDefined(tag)) {
			closeDropdown();
			return;
		}
		innerValue(editableValue, tag.substring(1), "#");
	}

	function handleChangeDate(event: CustomEvent): void {
		const date = event.detail.value;
		innerValue(editableValue, `(${date})`, "!");
	}

</script>

<div
	on:input={handleEditorEdit}
	on:keyup={handleKeyUp}
	class="wx-todo_editor"
	contenteditable="true"
	data-placeholder={placeholder}
	tabindex="0"
	bind:this={node}>
	{value}
</div>

{#if editor.dropdown}
	{#if editor.dropdown.type === 'menu'}
		<Menu
			id={editor.id}
			coords={editor.dropdown.coords}
			data={editor.dropdown.data}
			let:item
			on:click={handleMenuItemClick}
			on:cancel={closeDropdown}>
			<Item label={item.label} clickable minWidth={'50px'} />
		</Menu>
	{/if}
	{#if editor.dropdown.type === 'datepicker'}
		<DatePicker
			id={editor.id}
			dateFormat={shape.date.format}
			coords={editor.dropdown.coords}
			cancel={closeDatePicker}
			date={editor.targetDate}
			on:change={handleChangeDate} />
	{/if}
{/if}

<style>
	.wx-todo_editor {
		cursor: text;

		width: 100%;
		height: 100%;
		outline: none;

		word-wrap: normal;
		word-break: break-word;
		white-space: pre-wrap;
	}
	[data-placeholder]:empty:before {
		content: attr(data-placeholder);

		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		line-height: var(--wx-line-height);
		color: var(--wx-color-font-disabled);
	}

</style>
