<script lang="ts">
	import {
		Toolbar,
		ToolbarMenu,
		ToolbarCombo,
		ToolbarSearch,
		Material,
	} from "@xbs/svelte-todo";
	import Template from "./Template.svelte";

	import type { IApi, IToolbarItem } from "@xbs/lib-todo";

	export let api: IApi;
	export let items: IToolbarItem[];

</script>

<Material>
	<Toolbar {api}>
		{#each items as item}
			{#if item.type === 'search'}
				<ToolbarSearch {api} />
			{:else if item.type === 'menu'}
				<ToolbarMenu {api} />
			{:else if item.type === 'combo'}
				<ToolbarCombo {api} />
			{:else if item.template}
				<Template template={item.template} />
			{:else if item.type}
				<Template template={item.type} />
			{/if}
		{/each}
	</Toolbar>
</Material>
