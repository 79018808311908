<script lang="ts">
	import { createEventDispatcher } from "svelte";
	import { isDefined } from "@xbs/lib-todo";
	import { Portal } from "@xbs/svelte-wx";

	import type { ICoords, TID } from "@xbs/lib-todo";

	export let id: TID | null = null;
	export let coords: ICoords;
	export let offset = 0;
	export let node: HTMLElement = null;

	$: if (node) {
		coords = getCorrectedCoords(node, coords, offset);
	}

	function getCorrectedCoords(node: HTMLElement, coords: ICoords, offset) {
		const bodyRect = document.body.getBoundingClientRect();
		const { offsetWidth, offsetHeight } = node;

		const scrollOffsetX = window.pageXOffset;
		const scrollOffsetY = window.pageYOffset;

		let y = coords.y + offset + scrollOffsetY;
		let x = coords.x + offset + scrollOffsetX;

		if (y + offsetHeight > bodyRect.bottom) {
			y = coords.y - offset - offsetHeight;
		}
		if (x + offsetWidth > bodyRect.right) {
			x = coords.x - offset - offsetWidth;
		}

		return { x, y };
	}

	const dispatch = createEventDispatcher();

	function handlePointerDown(event: any) {
		if (isDefined(node) && !node.contains(event.target)) {
			dispatch("cancel", {
				event,
			});
		}
	}

</script>

<svelte:window on:click={handlePointerDown} />

<Portal theme="material">
	<div
		data-popup-id={id}
		style="left: {coords.x}px; top: {coords.y}px;"
		class="wx-material-theme wx-todo_popup"
		bind:this={node}>
		<slot />
	</div>
</Portal>

<style>
	.wx-todo_popup {
		position: absolute;
		z-index: 9999;

		background: var(--wx-background);
		box-shadow: var(--wx-box-shadow);
		border-radius: var(--wx-border-radius);
	}

</style>
