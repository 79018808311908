<script lang="ts">
	import { createEventDispatcher, getContext } from "svelte";
	import { dateToString, getDateLocales, stringToDate } from "@xbs/lib-todo";
	import { Calendar } from "@xbs/svelte-wx";
	import Popup from "./Popup.svelte";

	export let id;
	export let coords;
	export let cancel = null;
	export let date = null;
	export let dateFormat = "%d %M %Y";
	export let convert = true;

	const dispatch = createEventDispatcher();
	const _ = getContext("wx-i18n").getGroup("calendar");

	let value = null;
	let initialDate: Date = date
		? stringToDate(date, dateFormat, true, getDateLocales(_))
		: null;

	if (date instanceof Date) initialDate = date;
	if (typeof date === "string") initialDate = new Date(date);
	if (!initialDate?.getTime()) initialDate = null;

	value = initialDate
		? new Date(
				initialDate.getFullYear(),
				initialDate.getMonth(),
				initialDate.getDate()
		  )
		: null;

	const close = () => {
		if (value) {
			dispatch("change", {
				value: convert
					? dateToString(value, dateFormat, getDateLocales(_))
					: value,
			});
		} else {
			cancel && cancel();
		}
	};

</script>

<Popup {id} {coords}>
	<Calendar cancel={close} bind:value />
</Popup>
