<script lang="ts">
	import { setCaretPosition, setNodeRange } from "@xbs/lib-dom";
	import { createEventDispatcher } from "svelte";

	export let placeholder = "";
	export let value = "";
	export let editable = true;
	export let editing = false;
	export let enableRange = false;

	let node: HTMLElement = null;

	$: if (node) {
		if (enableRange) {
			setNodeRange(node);
		} else {
			setCaretPosition(node);
		}
	}

	const dispatch = createEventDispatcher();

	function handleEditorEdit(): void {
		dispatch("editing", { value: node?.textContent || "" });
	}

	function handleOpenEditor(): void {
		if (!editable || editing) return;

		editing = true;
		dispatch("open", { value });
	}

	function handleCloseEditor(): void {
		if (!editing) return;

		editing = false;
		dispatch("close", { value });
	}

</script>

<div class="wx-todo_text" on:dblclick|stopPropagation={handleOpenEditor}>
	{#if editing && editable}
		<div
			on:blur={handleCloseEditor}
			on:input={handleEditorEdit}
			class="wx-todo_text__value"
			class:wx-todo_text__value--editing={editing}
			contentEditable={true}
			data-placeholder={placeholder}
			tabindex="0"
			bind:this={node}>
			{value}
		</div>
	{:else}
		<div class="wx-todo_text__value" tabindex="0">{value}</div>
	{/if}
</div>

<style>
	.wx-todo_text {
		display: flex;
		width: 100%;
		height: 100%;
		min-height: var(--wx-line-height);

		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		line-height: var(--wx-line-height);

		color: var(--wx-color-font);
	}
	.wx-todo_text__value {
		cursor: default;

		width: 100%;
		height: 100%;
		outline: none;

		word-wrap: normal;
		word-break: break-word;
		white-space: pre-wrap;
	}
	.wx-todo_text__value--editing {
		cursor: text;
	}
	[data-placeholder]:empty:before {
		content: attr(data-placeholder);

		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		line-height: var(--wx-line-height);
		color: var(--wx-color-font-disabled);
	}

</style>
