<script lang="ts">
	import { createEventDispatcher } from "svelte";
	import { locateID } from "@xbs/lib-dom";
	import { isDefined, isSameId } from "@xbs/lib-todo";
	import Popup from "./Popup.svelte";

	import type { ICoords, TID } from "@xbs/lib-todo";

	export let id: TID | null = null;
	export let data = [];
	export let coords: ICoords;
	export let offset = 0;
	export let width: string | number = "auto";
	export let height: string | number = "auto";

	export let clickable = true;

	let selected = null;

	const dispatch = createEventDispatcher();

	function handleMenuClick(event: MouseEvent): void {
		if (!clickable) return;

		selected = locateID(event, "data-item-id");
		dispatch("click", {
			id: selected,
		});
	}

	function handleMouseDown(event: KeyboardEvent): void {
		const index = data.findIndex(i => isSameId(i.id, selected));
		switch (event.code) {
			case "ArrowUp":
				event.preventDefault();
				selected = isDefined(selected)
					? data[index - 1]?.id
					: data[data.length - 1].id;
				break;
			case "ArrowDown":
				event.preventDefault();
				selected = isDefined(selected)
					? data[index + 1]?.id
					: data[0].id;
				break;
			case "Enter":
				event.preventDefault();
				dispatch("click", { id: selected });
				break;
		}
	}

</script>

<svelte:window on:keydown={handleMouseDown} />

<Popup {coords} {offset} {id} on:cancel>
	<ul
		class="wx-todo_menu"
		on:click|stopPropagation={handleMenuClick}
		style="width:{typeof width === 'number' ? `${width}px` : width};
			height:{typeof height === 'number' ? `${height}px` : height};">
		{#each data as item (item.id)}
			<li
				class="wx-todo_menu__item"
				class:wx-todo_menu__item--selected={isSameId(item.id, selected) && clickable}
				data-item-id={item?.disabled ? null : item?.id}>
				<slot {item} />
			</li>
		{/each}
	</ul>
</Popup>

<style>
	.wx-todo_menu {
		overflow: auto;
	}
	.wx-todo_menu,
	.wx-todo_menu__item {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	.wx-todo_menu__item--selected {
		background: var(--wx-background-selected);
	}

</style>
