<script lang="ts">
	import {
		dateToString,
		getDateLocales,
		ICoords,
		isDefined,
		isSameId,
		IStateDueDate,
		TID,
	} from "@xbs/lib-todo";
	import { createEventDispatcher, getContext } from "svelte";
	import DatePicker from "../../../library/DatePicker.svelte";
	import Icon from "../../../library/Icon.svelte";

	export let id: TID = null;
	export let format = "%d %M %Y";
	export let date: Date | string = null;
	export let validate = true;
	export let completed = false;
	export let datePicker: IStateDueDate;

	const _ = getContext("wx-i18n").getGroup("todo");
	const _c = getContext("wx-i18n").getGroup("calendar");
	const dispatch = createEventDispatcher();

	let node: HTMLElement = null;
	let coords: ICoords = null;
	let dueDate: Date | null = null;
	let css = "wx-todo_date";

	$: open = isSameId(datePicker?.id, id) && datePicker?.open;

	$: if (node) {
		if (open) {
			const bodyCoords = document.body.getBoundingClientRect();
			const nodeCoords = node.getBoundingClientRect();

			let x = nodeCoords.left;
			let y = nodeCoords.bottom;

			if (nodeCoords.right >= bodyCoords.right) {
				x = bodyCoords.right - nodeCoords.width;
			}
			if (nodeCoords.bottom >= bodyCoords.bottom) {
				y = bodyCoords.bottom - nodeCoords.height;
			}
			coords = { x, y };
		}

		if (date instanceof Date) dueDate = date;
		if (typeof date === "string") dueDate = new Date(date);
		if (!dueDate?.getTime()) dueDate = null;

		if (validate && dueDate) {
			const currentDate = new Date();

			const diffCurrentDate = new Date(
				currentDate.getFullYear(),
				currentDate.getMonth(),
				currentDate.getDate()
			);
			const diffDueDate = new Date(
				dueDate.getFullYear(),
				dueDate.getMonth(),
				dueDate.getDate()
			);

			if (diffCurrentDate.getTime() <= diffDueDate.getTime()) {
				css = "wx-todo_date--current";
			} else {
				css = "wx-todo_date--expired";
			}
		}
	}

	function closeDatePicker(event) {
		dispatch("action", {
			action: "set-state-due-date",
			data: {
				id,
				value: typeof event === "undefined" ? null : dueDate,
				open: false,
			},
		});

		css = "wx-todo_date";
		dueDate = date = null;
	}

	function handleChangeDate(event: CustomEvent): void {
		const value = event.detail.value;
		dispatch("action", {
			action: "set-state-due-date",
			data: {
				id,
				value,
				open: false,
			},
		});
	}

</script>

{#if date || open}
	<div
		class="wx-todo_due-date"
		data-due-date-id={id}
		class:completed
		bind:this={node}>
		<Icon name="calendar" {css} size={14} />
		<span class={css}>
			{#if typeof date === 'string' && !isDefined(dueDate)}
				{date}
			{:else if dueDate instanceof Date}
				{dateToString(dueDate, format, getDateLocales(_c))}
			{:else}{_('Set due date')}{/if}
		</span>
	</div>
	{#if open && node}
		<DatePicker
			{id}
			{coords}
			convert={false}
			dateFormat={format}
			date={dueDate}
			cancel={closeDatePicker}
			on:change={handleChangeDate} />
	{/if}
{/if}

<style>
	.wx-todo_due-date {
		cursor: pointer;
		display: flex;
		align-items: center;

		width: fit-content;
		margin-top: 4px;

		height: var(--wx-font-size-s);
		font-size: calc(var(--wx-font-size-s) - 1px);
		font-weight: var(--wx-font-weight-medium);
		line-height: var(--wx-font-size-s);
	}
	.wx-todo_due-date span {
		margin-left: 2px;
	}
	:global(.wx-todo_date),
	.completed :global(.wx-todo_date--current),
	.completed :global(.wx-todo_date--expired) {
		cursor: pointer;
		color: var(--wx-todo-date-color-regular);
	}

</style>
